import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import lightTheme from './themes/light';
import darkTheme from './themes/dark';

const App = () => {

  const stored = localStorage.getItem("isDarkMode");
  const [isDarkMode, setIsDarkMode] = useState(
    stored === "true" ? true : false
  );


  const [backgroundImage, setBackgroundImage] = useState(null);
  const [boxColor, setBoxColor] = useState(null);
  const [Photographer, setPhotographer] = useState(null);
  const [PhotographerUrl, setPhotographerUrl] = useState(null);

  useEffect(() => {
    fetch('./.netlify/functions/unsplash-fetch')
    .then((response) => response.json())
    .then(
      (data) => {
      setBackgroundImage(data.urls.regular)
      setBoxColor(data.color)
      setPhotographer(data.user.name)
      setPhotographerUrl(data.user.links.html)

      document.querySelector('link[rel="icon"]').style.fill = "red";
    });

  }, []);  


  return <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
    <Page bg={backgroundImage}>
        <Main>
          <TopBar bg={boxColor}/>
          <Bio>
          <p><Wave>👋</Wave> Hej. Mit navn er Kasper Andreassen.</p>
          <p>Jeg arbejder til dagligt med UX & marketing hos <a href="https://epay.dk" target="_blank" rel="noopener noreferrer">ePay</a>.</p>
          <p>Du kan kontakte mig på <a href="https://www.linkedin.com/in/kasperandreassen/" target="_blank" rel="noopener noreferrer">LinkedIn</a> eller via <a href="mailto:hej@kasper.dk">e-mail</a>.</p>
          </Bio>
        </Main>
        <ThemeSwitcher
        onClick={() => {
          setIsDarkMode(!isDarkMode);
          localStorage.setItem("isDarkMode", !isDarkMode);
        }}
        >
          {isDarkMode ? "🌞" : "🌛"}
        </ThemeSwitcher>
        <Credit>Billede af <a href={PhotographerUrl + '?utm_source=kasper.dk&utm_medium=referral'} target="_blank" rel="noopener noreferrer">{Photographer}</a> via <a href="https://unsplash.com/?utm_source=kasper.dk&utm_medium=referral" target="_blank" rel="noopener noreferrer">Unsplash</a></Credit>
    </Page>
  </ThemeProvider>
}

const ThemeSwitcher = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    background-color:${props => props.theme.colors.boxBackground};
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
`;

const Credit = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color:${props => props.theme.colors.boxBackground};
    padding: 4px;
    border-radius: 4px;
    opacity: 0.8;
    font-style: italic;
    text-decoration: none;
    color: ${props => props.theme.colors.textColor};
    font-size: 12px;
    opacity: 0.8;

    a {
      color: ${props => props.theme.colors.linkColor};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      opacity: 1;
    }
`;

const Wave = styled.span`
    animation-name: wave-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;

    @keyframes wave-animation {
      0% { transform: rotate(  0.0deg) }
      10% { transform: rotate(-10.0deg) }
      20% { transform: rotate( 12.0deg) }
      30% { transform: rotate(-10.0deg) }
      40% { transform: rotate(  9.0deg) }
      50% { transform: rotate(  0.0deg) }
      100% { transform: rotate(  0.0deg) }
  }
`;

const Bio = styled.div`
  background-color:${props => props.theme.colors.boxBackground};
  padding: 12px;
  
  p {
    margin: 0;
    font-weight: 300;
    text-align: center;
    line-height: 2;
    font-size: 16px;
    color: ${props => props.theme.colors.textColor};
  }

  a {
    color: ${props => props.theme.colors.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media only screen and ( min-width: 768px ) {
    padding: 48px;

      p {
        font-size: 24px;
      }

  }
`;

const TopBar = styled.div`
  background-color: ${props => props.bg};
  border-radius: 4px 4px 0 0;
  height: 24px;
`;

const Main = styled.main`
  z-index: 1;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
  border-radius: 4px;
  margin: 24px;
`;

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(${props => props.bg});

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${props => props.theme.colors.background};
  }
`;

export default App;
